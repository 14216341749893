import React, { useEffect, useState } from "react";
import styles from './admin-betting-company-modal.module.less';
import oddsService from 'services/odds-service';
import AdminImagesBlock from 'components/block-elements/admin-images/admin-images';
import Modal from "../modal/modal";

const AdminBettingCompanyModal = (props) => {

    const [company, setCompany] = useState({
        id: '',
        createdAt: '',
        lastUpdated: '',
        logo: '',
        name: '',
        url: ''
    });
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageId, setImageId] = useState('');

    useEffect(() => {
        setLoading(true);
        getCompany();
    }, []);

    const getCompany = async() => {
        await oddsService.getBettingCompanieById(props.id)
            .then(res => {
                setCompany(res.data);
            }).catch(err => {

            }).finally(() => {
                setLoading(false);
            });
    } 

    const setImage = (image) => {
        company.logo = image.url;
        setImageId(image.id);
    }

    const onClose = () => {
        setIsModalOpen(false);
    }

    const save = async() => {
        const model = {
            id: company.id,
            url: company.url,
            name: company.name,
            logoId: imageId
        }

        await oddsService.updateBettingCompany(model)
            .then(res => {
                props.chngedCompany(res.data);
                props.setIsOpen();
            }).catch(err => {

            }).finally(() => {

            });
    }

    return (
        <>
            <div className={styles.closeBlock}>
                <button className={styles.closeButton} onClick={() => props.setIsOpen(false)}>X</button>
            </div>
            {!loading ? <div className={styles.main}>
                <div className={['mx-auto', 'mt-4'].join(' ')}>
                    <div>
                        Betting company Id:
                    </div>
                    <div>
                        <input type="text" defaultValue={company.id} readOnly></input>
                    </div>
                </div>
                <div className={['mx-auto', 'mt-4'].join(' ')}>
                    <div>
                        Name:
                    </div>
                    <div>
                        <input type="text" defaultValue={company.name} onChange={(e) => {setCompany({...company, name: e.target.value})}}></input>
                    </div>
                </div>
                <div className={['mx-auto', 'mt-4'].join(' ')}>
                    <div>
                        URL:
                    </div>
                    <div>
                        <input type="text" defaultValue={company.url} onChange={(e) => {setCompany({...company, url: e.target.value})}}></input>
                    </div>
                </div>
                <div className={['mx-auto', 'mt-4'].join(' ')}>
                    <div>
                        Last Updated:
                    </div>
                    <div>
                        <input type="text" defaultValue={company.lastUpdated} readOnly></input>
                    </div>
                </div>
                <div className={['mx-auto', 'mt-4'].join(' ')}>
                    <div>
                        Logo:
                    </div>
                    <div>
                        <img onClick={() => {setIsModalOpen(true)}} className={styles.logo} src={company.logo}></img>
                    </div>
                </div>
                <div className={['mx-auto', 'mt-4'].join(' ')}>
                    <button onClick={save}>Save</button>
                </div>
            </div> : <div>Loading...</div>}
            {isModalOpen && <Modal>
                <AdminImagesBlock onClose={onClose} setIsOpen={onClose} setImage={setImage} imageType={4} />
            </Modal>}
        </>
    )
}

export default AdminBettingCompanyModal;