import React, { useEffect, useState } from "react";
import oddsService from 'services/odds-service';
import styles from './admin-betting-company-block.module.less';
import Modal from "../modal/modal";
import AdminBettingCompanyModal from 'components/block-elements/admin-betting-company-modal/admin-betting-company-modal';

const AdminBettingCompanyBlock = (props) => {

    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [modalOpenId, setModalOpenId] = useState(null);
    const [pagesCount ,setPagesCount] = useState([]);
    const [page, setPage] = useState(0);

    useEffect(() => {
        setLoading(true);
        getOddsCompanies()
    }, []);

    useEffect(() => {
        setLoading(true);
        getOddsCompanies()
    }, [page]);

    const getOddsCompanies = async() => {
        await oddsService.getBettingCompanies(search, page * take, take)
            .then(res => {
                setCompanies(res.data.data);
                setPagesCount(Array.from(Array(Math.ceil(res.data.total / 10)).keys()));
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false);
            })
    }

    const closeModal = () => {
        setModalOpenId(null);
    }

    const changeCompany = async(company) => {
        setLoading(true);
        await getOddsCompanies();
    }

    const chagePage = (e) => {
        setPage(e.target.name);
    }

    return (<>
        {!loading ? 
        <div className="mb-8">
            {companies.map(c => (
                <div onClick={() => setModalOpenId(c.id)} key={c.id} className={['ml-2', 'mr-2', 'mt-2', styles.mainBox].join(' ')}>
                <div className={[styles.imagebox, 'mr-2', 'pt-3', 'pl-2'].join(' ')}>
                    <img className={styles.iamge} src={c.logo}></img>
                </div>
                    <div>
                        <div className="ml-2">
                            {c.id}
                        </div>
                        <div className="ml-2">
                            {c.name}
                        </div>
                        <div className="ml-2">
                            {c.url}
                        </div>
                    </div>
                </div>
            ))}
            <div className={styles.flexButtonGroup}>
          <div className={styles.buttonsGroups}>
            {pagesCount &&
              pagesCount.length > 0 &&
              pagesCount.map((el, i) => {
                return (
                  <button
                    className={`${el == page ? styles.activePagButton : ''} ${styles.button}`}
                    name={el}
                    onClick={chagePage}>
                    {el + 1}
                  </button>
                );
              })}
          </div>
        </div>
        </div> : 
        <div>Loading...</div>}
        {modalOpenId && <Modal setIsOpen={closeModal}>
                        <AdminBettingCompanyModal id={modalOpenId} chngedCompany={changeCompany} setIsOpen={closeModal}/>
                    </Modal>}
    </>)
}

export default AdminBettingCompanyBlock;