import React from 'react';
import PageLayout from 'components/pageLayout';
import { Helmet } from 'react-helmet'
import AdminSubNav from 'components/navigations/admin-subnavigation/admin-subnavigation';
import AdmimBettingBlock from 'components/block-elements/admin-betting-company-block/admin-betting-company-block';

const customBettingCompanies = () => {
    return (
        <>
            <Helmet title='Admin Panel Betting'></Helmet>
            <PageLayout>
                <AdminSubNav />
                <AdmimBettingBlock />
            </PageLayout>
        </>
      );
}

export default customBettingCompanies;